import * as React from "react";
import { graphql } from "gatsby";
import Title from "@components/UI-Elements/Typography/Title";
import SEO from "@components/seo";
import { SpCoin } from "@common/types/supabase";
import CoinPrice from "@components/Modules/dashboard/coin-price";
import Layout from "@components/layout";

export const query = graphql`
  query ($coingecko_id: String!) {
    supabaseCoins(coingecko_id: { eq: $coingecko_id }) {
      id
      name
      coingecko_id
      tags
      image {
        thumb
        small
        large
      }
      links {
        twitter_screen_name
        facebook_username
        bitcointalk_thread_identifier
        telegram_channel_identifier
        subreddit_url
      }
    }
  }
`;

const CoinPage: React.FC = (data: any) => {
  const coin: SpCoin = data.data.supabaseCoins;
  const title = `Der aktuelle Preis für ${coin.name}`;
  return (
    <Layout>
      <SEO title={title} robots="nofollow" />
      <Title>{title}</Title>
      <CoinPrice coin={coin.coingecko_id || ""} />
    </Layout>
  );
};
export default CoinPage;
